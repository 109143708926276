import React from "react";

import Imageset from '../components/imageset';
import Footer from '../components/footer';
  
const About = () => {
  return (
    <>
  <br /><br />
  <Imageset/>




  <Footer/>
    </>
  );
};
  
export default About;