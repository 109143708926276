import React from 'react'
import Contactform from '../components/contactform'
import Footer from '../components/footer';

const Book = () => {
  return (
    <>
     <Contactform/>




<Footer/>
    </>
  )
}

export default Book