import React from 'react'
import './button.css'
const Buttoon = (props) => {
  return (
    <>
 <button className='bttn'>{props.btntext}</button>
    </>
 
  )
}

export default Buttoon